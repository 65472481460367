import React from "react";
import { Carousel, Card, Button, Row, Col, Typography, Divider } from "antd";
import {
  FileTextOutlined,
  DollarOutlined,
  SmileOutlined,
  ShoppingCartOutlined,
  BankOutlined,
  LineChartOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HomeJsonData from "../data/home.json";
import BusinessTypeCards from "../components/businessTypeCards";
import { useViewport } from "../components/useViewport";

const { Title } = Typography;

const IndexPage = () => {
  const { width } = useViewport();
  return (
    <Layout>
      <div>
        <Carousel autoplay={false}>
          {HomeJsonData.content.banner.map((item) => (
            <div className={item.imageClass}>
              <Row style={{ paddingTop: "8%" }}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                />
                <Col
                  style={{ textAlign: "-webkit-right", paddingRight: "5%" }}
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <div className="bannerCard">
                    <Row>
                      <h3 className="bannerContent">{item.title}</h3>
                    </Row>
                    <Row>
                      <p className="bannerPara">{item.description}</p>
                    </Row>
                    <Row className="bannerBtnRow">
                      <Button className="bannerBtn">{item.bannerBtn}</Button>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
        <div className="contentDiv">
          <Row style={{ width: "100%", margin: "0 auto" }}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              style={{
                margin: "25px 4% 25px 4%",
                width: "calc(50% - ( ( 4% ) * 0.5 ) )",
              }}
            >
              <img src={HomeJsonData.content1.mainImg} />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              style={{
                marginTop: "25px",
                marginBottom: "25px",
                width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                marginRight: "6%",
              }}
            >
              <div>
                <h2 className="content1Title">{HomeJsonData.content1.title}</h2>
                <p className="content1Para">{HomeJsonData.content1.mainPara}</p>
              </div>
              <Button type="primary" size="large" className="getStartBtn">
                Get Started
              </Button>
              <Button size="large" className="requestBtn">
                <FileTextOutlined style={{ verticalAlign: "text-top" }} />{" "}
                Request a demo
              </Button>
              <Divider />
              <div>
                <h3 className="content1subTitle">
                  {HomeJsonData.content1.subTitle}
                </h3>
                <p className="content1subPara">
                  <span className="content1subParaAestric">*</span>
                  {HomeJsonData.content1.subPara}
                </p>
              </div>
            </Col>
          </Row>
          <div>
            <img src={HomeJsonData.content1.subImg} />
          </div>
          <Divider />
          <div>
            <h2 className="solutions">{HomeJsonData.content2.title}</h2>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 13 }}>
                <p className="content2Para">{HomeJsonData.content2.para}</p>
              </Col>
              <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 11 }} />
            </Row>
          </div>
          <div>
            <Row>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <BusinessTypeCards
                  title="Finance"
                  description="Integrate business processes with various financial functions to facilitate communication and information sharing, resulting in dramatically increased productivity and speed."
                  icon={
                    <div className="businessIcon">
                      <DollarOutlined />
                    </div>
                  }
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <BusinessTypeCards
                  title="Human Capital"
                  description="CW HCM Suite empowers HR professionals to streamline employee information, new hires, employee onboarding, payroll, promotions and compensation changes."
                  icon={
                    <div className="businessIcon">
                      <SmileOutlined />
                    </div>
                  }
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <BusinessTypeCards
                  title="Procurement"
                  description="CW helps you in enabling control and compliance by dynamically and globally managing suppliers, processes, budgets, approvals, and payments."
                  icon={
                    <div className="businessIcon">
                      <ShoppingCartOutlined />
                    </div>
                  }
                />
              </Col>
            </Row>
            <Row className="rowCards">
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <BusinessTypeCards
                  title="Manufacturing"
                  description="With CW Manufacturing, your people, processes and machines have the ability to adapt in real-time with cognitive manufacturing solutions that transform the value chain beyond the factory walls."
                  icon={
                    <div className="businessIcon">
                      <BankOutlined />
                    </div>
                  }
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <BusinessTypeCards
                  title="Sales & Distribution"
                  description="With CW Manufacturing, your people, processes and machines have the ability to adapt in real-time with cognitive manufacturing solutions that transform the value chain beyond the factory walls."
                  icon={
                    <div className="businessIcon">
                      <LineChartOutlined />
                    </div>
                  }
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <BusinessTypeCards
                  title="Supply Chain"
                  description="Manage relationships with suppliers, partners, and contract manufacturers across your supply chain with Dashboards for status monitoring, Rapid onboarding & Enhanced compliance"
                  icon={
                    <div className="businessIcon">
                      <LikeOutlined />
                    </div>
                  }
                />
              </Col>
            </Row>
            {/* <Row>
            {HomeJsonData.content2.cards.map((item) => (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                className="colCards"
              >
                <Card
                  title={item.title}
                  className="businessCards"
                  extra={
                    <div
                      className="businessIcon"
                    >
                      <SmileOutlined style={{ verticalAlign: "baseline" }} />
                    </div>
                  }
                  bodyStyle={{ paddingTop: 10 }}
                >
                  <p className="businessDesc">{item.description}</p>
                  <Button type="primary" size="large" className="businessBtn">
                    {item.btn} <ArrowRightOutlined style={{ verticalAlign: "baseline", fontSize: "18px" }} />
                  </Button>
                </Card>
              </Col>
            ))}
          </Row> */}
          </div>
          <div className="featuresContent">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
                <h2 className="content3Title">{HomeJsonData.content3.title}</h2>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
                <p className="content3Para">{HomeJsonData.content3.para}</p>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }}>
                <Button type="primary" size="large" className="featuresBtn">
                  View all features
                </Button>
              </Col>
            </Row>
          </div>
          <div className="cloudContent">
            <Carousel
              // afterChange={onChange}
              // dots={false}
              autoplay={true}
              dotPosition="right"
              effect="fade"
            >
              {HomeJsonData.content4.cwExtra.map((item) => (
                <div>
                  <Row style={{ width: "100%", margin: "0 auto" }}>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 10 }}
                      lg={{ span: 10 }}
                      style={{
                        marginTop: "25px",
                        marginBottom: "25px",
                        width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                        marginRight: "6%",
                      }}
                    >
                      <div>
                        <h3 className="content4Title">{item.title}</h3>
                        <p className="content4Para">{item.para}</p>
                      </div>
                      <Divider />
                      <div>
                        <p>
                          <span className="content4subPara">
                            <span
                              style={{
                                color:
                                  item.title === "360° Analytics"
                                    ? "#4fc4f4"
                                    : item.title === "Built to scale"
                                    ? "#ff6186"
                                    : item.title === "Cloud Based ERP"
                                    ? "#fe865d"
                                    : "#4fc4f4",
                                fontSize: "32px",
                                marginRight: "11.2px",
                                paddingTop: "5px",
                              }}
                            >
                              *
                            </span>
                            {item.subPara}
                          </span>
                        </p>
                        {item.subPara1 ? (
                          <p>
                            <span className="content4subPara">
                              <span
                                style={{
                                  color: "#4fc4f4",
                                  fontSize: "32px",
                                  marginRight: "11.2px",
                                  paddingTop: "5px",
                                }}
                              >
                                *
                              </span>
                              {item.subPara1}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 10 }}
                      lg={{ span: 10 }}
                      className="cloudImages"
                    >
                      <img src={item.img} />
                    </Col>
                  </Row>
                </div>
              ))}
            </Carousel>
          </div>
          <Divider />
          <div>
            <Row style={{ width: "100%", margin: "0 auto" }}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
                style={{
                  margin: "30px 4% 25px 4%",
                  width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                }}
              >
                <img src={HomeJsonData.content5.img} />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
                style={{
                  marginTop: "30px",
                  marginBottom: "25px",
                  width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                  marginRight: "6%",
                  marginLeft: "30px",
                }}
              >
                <div>
                  <h2 className="content5Title">
                    {HomeJsonData.content5.title}
                  </h2>
                  <p className="content5Para">{HomeJsonData.content5.para}</p>
                </div>
                <Link to="contact">
                  <Button type="primary" size="large" className="getStartBtn">
                    Contact us
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
