import React from 'react';
import { Card, Button } from 'antd';
import 'antd/dist/antd.css';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Meta } = Card;

const BusinessTypeCards = (props) => {
    return (
        <div>
            <Card title={props.title} className="businessCards" extra={props.icon} bodyStyle={{paddingTop: 10}}>
                <p className="businessDesc">{props.description}</p>
                <Button type="primary" size="large" className="businessBtn">Learn More <span style={{fontSize: "23px", verticalAlign: "text-bottom"}}>&#8594;</span></Button>
            </Card>
        </div>
    )
}

export default BusinessTypeCards;